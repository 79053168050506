<template>
  <section class="directory">
    <h1 class="title">
      Directorio
    </h1>

    <ul v-if="directoryMembers" class="directory-list">
      <li v-for="member in directoryMembers" :key="member.id" class="directory-member">
        <figure class="image">
          <img v-if="member.image" :src="member.image.url" :alt="member.name" />
        </figure>

        <h1 class="name">{{ member.name }}</h1>

        <p class="position">{{ member.mainPosition }}</p>

        <p v-if="member.secondaryPosition" class="position">{{ member.secondaryPosition }}</p>

        <a v-if="member.email" :href="`mailto:${member.email}`" class="email">{{ member.email }}</a>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Directory',
  computed: {
    ...mapState(['directoryMembers'])
  },
  created () {
    this.getDirectoryMembers()
  },
  methods: {
    ...mapActions(['getDirectoryMembers'])
  }
}
</script>

<style lang="postcss" scoped>
.directory {
  @apply w-full max-w-screen-xl mx-auto py-20 px-10;
}

.title {
  @apply px-10 py-4 mx-auto mb-12 w-max bg-brand;
  @apply text-2xl font-bold text-white uppercase;
  @apply md:text-6xl;
}

.directory-list {
  @apply flex flex-wrap justify-center items-stretch max-w-screen-md mx-auto;
}

.directory-member {
  @apply w-48 m-5 text-center;
}
.image {
  @apply w-48 h-48 mb-3 bg-brand overflow-hidden;
}
.image img {
  @apply w-48 h-48 object-cover object-center;
}
.name {
  @apply mb-3 text-sm text-brand font-bold;
}
.position {
  @apply text-xs text-brand;
}
.email {
  @apply mt-3 text-xs text-black hover:text-brand transition-all duration-300 ease-linear;
}
</style>
