<template>
  <main v-if="aboutContent" class="about">
    <Hero :image="aboutContent.heroImage.url" :text="aboutContent.heroTitle" view="about" />

    <section class="bg-brand">
      <TwoColumnText
        :displayText="aboutContent.leftColumn"
        :text="aboutContent.rightColumn"
        brand
      />
    </section>

    <Directory />
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Hero from '@/components/layout/Hero'
import TwoColumnText from '@/components/layout/TwoColumnText'
import Directory from '@/components/Directory'

export default {
  name: 'About',
  title: 'Nosotros | Andinor',
  components: { Hero, TwoColumnText, Directory },
  computed: {
    ...mapState(['aboutContent'])
  },
  created () {
    this.getAboutContent()
  },
  methods: {
    ...mapActions(['getAboutContent'])
  }
}
</script>
